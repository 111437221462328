<template>

  <b-row>
    <b-col cols="6">
      <b-row>
        <!-- PROCEDENCIA -->
        <!-- <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label-cols="7"
            label-class="font-weight-bold text-right"
            label="Procedencia *"
            label-for="procedencia"
          >
            <b-form-input
              id="procedencia"
              v-model="matricula.procedencia"
              placeholder="Ingresa la procedenia"
            />
          </b-form-group>
        </b-col> -->

        <!-- PRIORITARIO -->
        <b-col cols="12">
          <b-form-group
            label-cols="9"
            label-class="font-weight-bold text-right"
            label="¿Estudiante prioritario?"
            label-for="prioritario"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.prioritario"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- JUNAEB -->
        <b-col cols="12">
          <b-form-group
            label-cols="9"
            label-class="font-weight-bold text-right"
            label='¿Beneficiario de "Junaeb"?'
            label-for="junaeb"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.junaeb"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- APRENDIZAJE -->
        <b-col cols="12">
          <b-form-group
            label-cols="9"
            label-class="font-weight-bold text-right"
            label="¿Presenta problemas de aprendizaje?"
            label-for="aprendizaje"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.aprendizaje"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- BENEFICIO chile solidario-->
        <b-col cols="12">

          <b-form-group
            label-cols="9"
            label-class="font-weight-bold text-right"
            label='¿Beneficiario de "Chile Solidario" o "Puente"?''
            label-for="beneficio"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.beneficio"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>
    </b-col>

    <b-col cols="6">
      <b-row>

        <!-- TRANSPORTE -->
        <b-col cols="12">
          <b-form-group
            label-cols="6"
            label-class="font-weight-bold text-right"
            label="¿Utiliza transporte escolar?"
            label-for="transporte"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.transporte"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        
        <!-- RELIGION -->
        <b-col cols="12">
          <b-form-group
            label-cols="6"
            label-class="font-weight-bold text-right"
            label="¿Se autoriza para cursar religión?"
            label-for="religion"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.religion"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        
        <!-- AUTORIZACION ALMUERZO -->
        <b-col cols="12">
          <b-form-group
            label-cols="6"
            label-class="font-weight-bold text-right"
            label="¿Se autoriza para salir a almorzar?"
            label-for="autorizacion"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.autorizacion"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- REPITENCIA -->
        <b-col cols="12">
          <b-form-group
            label-cols="6"
            label-class="font-weight-bold text-right"
            label="Repitente"
            label-for="repitencia"
            class="text-center"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="matricula.repitencia"
              class="custom-control-success ml-3 text-left"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  BCardSubTitle, BFormCheckbox, BButtonGroup,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../../components/Form/colLinea.vue';
import btnSubmit from '../../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
    BCardSubTitle, BFormCheckbox, BButtonGroup,
    vSelect,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      familiar: {
        camas: null,
        certificado_estudio: false,
        certificado_nacimiento: false,
        certificado_personalidad: false,
        hacinamiento: null,
        id_persona_rol_madre: null,
        id_persona_rol_padre: null,
        integrantes: null,
        nivel_educacional_madre: null,
        nivel_educacional_padre: null,
        renta: null,
        vive: null,
      },
      // options
      optionsNivelEducacion: [
        {
          nivel_educacional: 1,
          title: 'Básica',
        },
        {
          nivel_educacional: 2,
          title: 'Básica - Incompleta',
        },
        {
          nivel_educacional: 3,
          title: 'Media',
        },
        {
          nivel_educacional: 4,
          title: 'Media - Incompleta',
        },
        {
          nivel_educacional: 5,
          title: 'Técnico Nivel Superior',
        },
        {
          nivel_educacional: 6,
          title: 'Técnico Nivel Superior - Incompleta',
        },
        {
          nivel_educacional: 7,
          title: 'Universitaria',
        },
        {
          nivel_educacional: 8,
          title: 'Universitaria - Incompleta',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getMatriculaNucleo: 'matriculas/getMatriculaNucleo',
    }),
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // this.cargaFamiliar()
    console.log('this.matricula :', this.matricula)
  },
  methods: {
    ...mapActions({
      fetchMatriculaNucleo: 'matriculas/fetchMatriculaNucleo',
      createMatriculaNucleo: 'matriculas/addMatriculaNucleo',
    }),
    cargaFamiliar() {
      this.fetchMatriculaNucleo(this.matricula.id_matricula).then(() => {
        if (this.getMatriculaNucleo.status !== 'error') {
          this.familiar = {
            vive: this.getMatriculaNucleo.vive,
            renta: this.getMatriculaNucleo.renta,
            integrantes: this.getMatriculaNucleo.integrantes,
            camas: this.getMatriculaNucleo.camas,
            hacinamiento: this.getMatriculaNucleo.hacinamiento,
            certificado_nacimiento: this.getMatriculaNucleo.certificado_nacimiento === 1,
            certificado_estudio: this.getMatriculaNucleo.certificado_estudio === 1,
            certificado_personalidad: this.getMatriculaNucleo.certificado_personalidad === 1,
            nivel_educacional_padre: this.getMatriculaNucleo.nivel_educacional_padre,
            nivel_educacional_madre: this.getMatriculaNucleo.nivel_educacional_madre,
            id_persona_rol_padre: this.getMatriculaNucleo.id_persona_rol_padre,
            id_persona_rol_madre: this.getMatriculaNucleo.id_persona_rol_madre,
          }
        }
        this.cargando = false
      })
    },
    submitOption(tipo) {
      this.v$.familiar.$touch()
      if (!this.v$.familiar.$invalid) {
        const data = {
          vive: this.familiar.vive,
          renta: this.familiar.renta,
          integrantes: this.familiar.integrantes,
          camas: this.familiar.camas,
          hacinamiento: this.familiar.hacinamiento,
          certificado_nacimiento: this.familiar.certificado_nacimiento,
          certificado_estudio: this.familiar.certificado_estudio,
          certificado_personalidad: this.familiar.certificado_personalidad,
          nivel_educacional_padre: this.familiar.nivel_educacional_padre,
          nivel_educacional_madre: this.familiar.nivel_educacional_madre,
          id_persona_rol_padre: this.familiar.id_persona_rol_padre,
          id_persona_rol_madre: this.familiar.id_persona_rol_madre,
          id_matricula: this.matricula.id_matricula,
        }
        this.createMatriculaNucleo(data).then(() => {
          const statusPersonas = store.state.matriculas.status
          if (statusPersonas === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Núcleo familiar guardado 👍',
                icon: 'CheckIcon',
                text: 'El núcleo familiar fue editado con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 3000,
            })
            if (tipo === 2) {
              this.$router.replace({
                name: 'matriculas',
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: store.state.matriculas.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }
    },
  },
  validations() {
    return {
      familiar: {
        integrantes: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        camas: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        hacinamiento: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_padre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_madre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
