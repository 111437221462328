<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="ml-1 mt-50 text-secondary">
        Antecedentes personales de el/la estudiante
      </div>
      <div class="mr-1 mt-50 text-muted small">Campos obligatorios <span class="text-danger">*</span></div>
    </div>

    <!-- FORM -->
    <b-overlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25 mb-1">

        <!-- Estudiante -->
        <b-col cols="6">
          <b-row>

            <!-- RUT / IPE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                :label-for="estudiante.tipo_identificacion === 1 ? 'rut' : 'ipe'"
              >
                <template #label>
                  {{ estudiante.tipo_identificacion === 1 ? 'RUT' : 'IPE' }} <span class="text-danger">*</span>
                </template>
                <b-row>

                  <!-- RUT -->
                  <b-col :hidden="estudiante.tipo_identificacion === 2" cols="8">
                    <b-form-input
                      id="rut"
                      v-model="estudiante.rut"
                      placeholder="Ej: 26.636.993-1"
                      :state="v$.estudiante.rut.$error === true
                      ? false
                      : null"
                      :autofocus="crud === 'c'"
                      @keyup="estudiante.rut = formatRut(estudiante.rut)"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.rut.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.rut.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-col>
                  
                  <!-- IPE -->
                  <b-col v-if="estudiante.tipo_identificacion === 2" cols="8">
                    <b-form-input
                      id="ipe"
                      v-model="estudiante.ipe"
                      placeholder="Ej: 01122000"
                      :state="v$.estudiante.ipe.$error === true
                      ? false
                      : null"
                      @keyup="estudiante.ipe = formatRut(estudiante.ipe)"
                    />
                    <!-- Validaciones -->
                    <b-form-invalid-feedback
                      v-if="v$.estudiante.ipe.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.ipe.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </b-form-invalid-feedback>
                  </b-col>
                  
                  <!-- TIPO IDENTIFICACION -->
                  <b-col id="colTipo_identificacion" cols="4" class="pl-0">
                    <b-form-select
                    id="tipo-identificacion-select"
                    v-model="estudiante.tipo_identificacion"
                    :options="optionsTipoIdentificacion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    class="custom-select"
                    :class="v$.estudiante.tipo_identificacion.$error === true 
                      ? 'border-danger rounded' 
                      : ''"
                    @input="changeTipoIdentificacion"
                    />
                    <div
                      v-if="v$.estudiante.tipo_identificacion.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.tipo_identificacion.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </b-col>

                </b-row>
              </b-form-group>
            </b-col>

            <!-- NOMBRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nombre"
              >
                <template #label>
                  Nombre <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="nombre"
                  v-model="estudiante.nombre"
                  placeholder="Ingresa el nombre"
                  :state="v$.estudiante.nombre.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.nombre.$error"
                  id="nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO NOMBRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="segundo_nombre"
              >
                <template #label>
                  Segundo nombre
                </template>
                <b-form-input
                  id="segundo_nombre"
                  v-model="estudiante.segundo_nombre"
                  placeholder="Ingresa el segundo nombre"
                  :state="v$.estudiante.segundo_nombre.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.segundo_nombre.$error"
                  id="segundo_nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PRIMER APELLIDO -->
            <b-col cols="12" class="text-bold">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="primer_apellido"
              >
                <template #label>
                  Apellido paterno <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="primer_apellido"
                  v-model="estudiante.primer_apellido"
                  placeholder="Ingresa el apellido paterno"
                  :state="v$.estudiante.primer_apellido.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.primer_apellido.$error"
                  id="primer_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.primer_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SEGUNDO APELLIDO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="segundo_apellido"
              >
                <template #label>
                  Apellido materno <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="segundo_apellido"
                  v-model="estudiante.segundo_apellido"
                  placeholder="Ingresa el apellido materno"
                  :state="v$.estudiante.segundo_apellido.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.segundo_apellido.$error"
                  id="segundo_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- FECHA DE NACIMIENTO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="fecha_nacimiento"
              >
                <template #label>
                  Fecha nacimiento <span class="text-danger">*</span>
                </template>
                <flat-pickr
                  v-model="estudiante.fecha_nacimiento"
                  class="form-control"
                  :config="config.locale"
                  placeholder="Selecciona una fecha"
                  name="date"
                  :class="v$.estudiante.fecha_nacimiento.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <div
                  id="asistentesInfo"
                  v-if="v$.estudiante.fecha_nacimiento.$error"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.fecha_nacimiento.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- GENERO -->
            <b-col cols="12">
              <b-form-group
                id="group-genero"
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="genero"
              >
                <template #label>
                  Genero <span class="text-danger">*</span>
                </template>
                
                <v-select
                  id="genero"
                  v-model="estudiante.genero"
                  placeholder="Selecciona un genero"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.genero"
                  :options="optionsGeneros"
                  :class="v$.estudiante.genero.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsGeneros.length === 0" -->
                <div
                  v-if="v$.estudiante.genero.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.genero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            
          </b-row>
        </b-col>

        <b-col cols="6">
          <b-row>

            <!-- CORREO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="correo"
              >
                <template #label>
                  Correo <span class="text-danger">*</span>
                </template>
                <b-input-group
                  :class="v$.estudiante.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="correo"
                    v-model="estudiante.correo"
                    placeholder="Ingresa el correo electrónico"
                    :class="v$.estudiante.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    :state="v$.estudiante.correo.$error === true
                    ? false
                    : null"
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CELULAR -->
            <b-col
            cols="12"
            >
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="celular"
              >
                <template #label>
                  Celular <span class="text-danger">*</span>
                </template>
                <b-input-group
                  :class="v$.estudiante.celular.$error === false ? '' : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="celular"
                    v-model="estudiante.celular"
                    placeholder="Ingresa el número de celular"
                    :state="v$.estudiante.celular.$error === true
                    ? false
                    : null"
                    @keyup='estudiante.celular = formatSoloNumerosMaxLenght(estudiante.celular, 11)'
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.estudiante.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CURSO -->
            <b-col cols="12" >
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="id_cursos"
              >
                <template #label>
                  Curso <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="estudiante.id_cursos"
                  placeholder="Selecciona el curso..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.id_cursos"
                  :options="optionsCursos"
                  :class="v$.estudiante.id_cursos.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  @input="changeTipoIdentificacion()"
                />
                <div
                  v-if="v$.estudiante.id_cursos.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.id_cursos.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            
            <!-- FECHA INGRESO -->
            <b-col cols="12" >
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="fecha_ingreso"
              >
                <template #label>
                  Fecha ingreso
                </template>
                <b-form-datepicker
                  id="fecha_ingreso"
                  v-model="estudiante.fecha_ingreso"
                  placeholder="Abrir calendario"
                  hide-header
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short'
                  }"
                  start-weekday="1"
                  locale="es-CL"
                  label-help=""
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.fecha_ingreso.$error"
                  id="fecha_ingresoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.fecha_ingreso.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- PIE -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label="¿Estudiante PIE?"
                label-for="pie"
              >
                <!-- label-cols-md="6" -->
                <b-form-checkbox
                  checked="true"
                  v-model="matricula.pie"
                  class="custom-control-success mt-50 text-left"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      

      <b-tabs class="border rounded pt-50 pb-2" >
        <!-- Direccion -->
        <b-tab lazy active>
          <template #title>
            <feather-icon
              icon="MapPinIcon"
              size="16"
              class="mr-0 mr-sm-50"
              :class="{'text-danger': hasDireccionErrors}"
            />
            <span 
              class="d-none d-sm-inline"
              :class="{'text-danger': hasDireccionErrors}"
            >
              Dirección
            </span>
          </template>

          <col-linea style="margin-top: -15px !important;"/>
          
          <b-row class="mt-3">

            <!-- COMUNA -->
            <b-col cols="6" >
              <b-form-group
                label-class="font-weight-bold mt-25 text-right"
                content-cols="9"
                label="Comuna *"
                label-for="id_comuna"
              >
                <template #label>
                  Comuna <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="estudiante.id_comuna"
                  placeholder="Selecciona una comuna..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :reduce="option => option.id_comuna"
                  :options="optionsComunas"
                  :class="v$.estudiante.id_comuna.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  :disabled="optionsComunas.length === 0"
                />
                <div
                  v-if="v$.estudiante.id_comuna.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.id_comuna.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- CALLE -->
            <b-col cols="6">
              <b-form-group
                label-class="font-weight-bold mt-25 text-right"
                content-cols="9"
                label-for="nombre_calle"
              >
                <template #label>
                  Dirección <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="nombre_calle"
                  v-model="estudiante.nombre_calle"
                  placeholder="Ingresa el nombre de la calle"
                  :state="v$.estudiante.nombre_calle.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.nombre_calle.$error"
                  id="nombre_calleInfo"
                >
                  <p
                    v-for="error of v$.estudiante.nombre_calle.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NUMERO -->
            <b-col cols="6" >
              <b-form-group
                label-class="font-weight-bold mt-25 text-right"
                content-cols="9"
                label-for="numero"
              >
                <template #label>
                  Número <span class="text-danger">*</span>
                </template>
                <b-input-group
                  :class="v$.estudiante.numero.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    #
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="estudiante.numero"
                    placeholder="Ingresa el número de la calle"
                    :state="v$.estudiante.numero.$error === true
                    ? false
                    : null"
                    @keyup='estudiante.numero = formatSoloNumerosMaxLenght(estudiante.numero, 8)'
                  />
                </b-input-group>
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.estudiante.numero.$error"
                  id="numeroInfo"
                >
                  <p
                    v-for="error of v$.estudiante.numero.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </b-tab>

        <!-- Datos escolares -->
        <b-tab lazy>
          <template #title>
            <feather-icon
              icon="ToggleLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Información</span>
          </template>
          
          <col-linea style="margin-top: -15px !important;" />
          
          <TabEscolares
            class="mt-3"
            crud="u"
            :matricula="matricula"
          />
        </b-tab>
      </b-tabs>


      <!-- Action Buttons -->
      <!-- <col-linea
        style="margin-left: -20px !important; margin-right: -20px !important;"
      />
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="4"
        >
          <b-button-group>
            <btnSubmit
              class="rounded mr-1"
              variant="primary"
              btnText="Guardar y salir"
              modulo="matriculas"
              @processBtn="submitOption(2)"
            />
            <btnSubmit
              class="rounded"
              variant="primary"
              btnText="Guardar"
              modulo="matriculas"
              @processBtn="submitOption(1)"
            />
          </b-button-group>
        </b-col>
      </b-row> -->
    </b-overlay>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader,
  BCardTitle, BFormCheckbox, BCardSubTitle, BInputGroup, BInputGroupPrepend,
  BFormDatepicker, BFormInvalidFeedback, BButtonGroup, BOverlay, BTabs, BTab,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapActions, mapGetters, mapState } from 'vuex'

import store from '@/store/index'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'


// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

import TabEscolares from './TabEstudiante/TabEscolares.vue'

export default {
  components: {
    BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader,
    BCardTitle, BFormCheckbox, BCardSubTitle, BInputGroup, BInputGroupPrepend,
    BFormDatepicker, BFormInvalidFeedback, BButtonGroup, BOverlay, BTabs, BTab,
    BFormSelect,
    vSelect,
    flatPickr,

    // Componentes reciclados
    colLinea,
    btnSubmit,

    TabEscolares,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      configs: {
        cargando: true,
      },
      estudiante: [],
      // otros
      config: {
        time: null,
        wrap: true,
        noCalendar: false,
        locale: {
          locale: SpanishLocale,
        },
        showMonths: 1, // sirve para un desde hasta, muestra más calendarios
      },
      // options
      optionsComunas: [],
      optionsCursos: [],
    }
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getComunas: 'ceds/getComunas',
      getPersona: 'personas/getPersona',
      getCursos: 'cursos/getCursos',
      getMatricula: 'matriculas/getMatricula',
    }),
    ...mapState({
      optionsTipoIdentificacion: state => state.tiposIdentificaciones.tiposIdentificaciones,
      optionsGeneros: state => state.generos.generos
    }),
    hasDireccionErrors() {
      return this.v$.estudiante.id_comuna.$error || this.v$.estudiante.nombre_calle.$error || this.v$.estudiante.numero.$error;
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchComunas: 'ceds/fetchComunas',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchMatricula: 'matriculas/fetchMatricula',
      addMatricula: 'matriculas/addMatricula',
    }),
    async initialize() {
      await Promise.all([
        this.cargaComunas(),
        this.cargaCursos(),
      ])

      if (this.crud === 'u') {
        this.cargarEstudiante()
      } else {
        this.estudiante = this.matricula.estudiante
        this.configs.cargando = false
      }

    },
    async cargaComunas() {
      await this.fetchComunas()
      this.optionsComunas = []
      this.getComunas.forEach(comuna => {
        this.optionsComunas.push({
          id_comuna: comuna.RefCountyId,
          nombre: comuna.Description,
        })
      })
    },
    async cargaCursos() {
      await this.fetchCursosEstablecimiento(this.getUser.id_establecimiento)
      this.optionsCursos = []
      this.getCursos.forEach(curso => {
        const nombre = curso.nombre+' '+ curso.letra
        this.optionsCursos.push({
          id_cursos: curso.id,
          title: nombre,
        })
      })
    },
     cargarEstudiante() {
      const comuna = this.getComunas.find(c => c.RefCountyId === this.matricula.id_comuna)
      const id_comuna = typeof comuna === 'undefined' ? null : this.matricula.id_comuna
      this.estudiante = {
        // persona
        tipo_identificacion: this.matricula.tipo_identificacion,
        rut: this.matricula.rut_completo,
        dv: this.matricula.dv,
        nombre: this.matricula.nombre,
        segundo_nombre: this.matricula.segundo_nombre,
        primer_apellido: this.matricula.primer_apellido,
        segundo_apellido: this.matricula.segundo_apellido,
        genero: this.matricula.genero,
        fecha_nacimiento: this.matricula.fecha_nacimiento,

        // apoderado
        id_persona_rol_apoderado: this.matricula.id_persona_rol_apoderado,
        id_persona_rol_apoderado_suplente: this.matricula.id_persona_rol_apoderado_suplente,

        // dirección
        nombre_calle: this.matricula.nombre_calle,
        numero: this.matricula.numero,
        celular: this.matricula.celular,
        correo: this.matricula.correo,
        id_comuna,
        id_pais: this.matricula.id_pais,

        // antecedentes escolares
        fecha_ingreso: this.matricula.fecha_ingreso,
        id_establecimiento: this.matricula.id_establecimiento,
        id_cursos: this.matricula.id_cursos,
        procedencia: this.matricula.procedencia,
        repitencia: this.matricula.repitencia === 1,
        prioritario: this.matricula.prioritario === 1,
        beneficio: this.matricula.beneficio === 1,
        pie: this.matricula.pie === 1,
        religion: this.matricula.religion === 1,
        junaeb: this.matricula.junaeb === 1,
        autorizacion: this.matricula.autorizacion === 1,
        aprendizaje: this.matricula.aprendizaje === 1,
        transporte: this.matricula.transporte === 1,
      }
      this.configs.cargando = false
    },
    submitOption(tipo) {
      this.v$.estudiante.$touch()
      if (!this.v$.estudiante.$invalid) {
        const rut = this.divideRut(this.estudiante.rut) // mixin format
        const data = {
          // persona
          tipo_identificacion: this.estudiante.tipo_identificacion,
          rut: rut.rutSinDv,
          dv: rut.dv,
          nombre: this.estudiante.nombre,
          primer_apellido: this.estudiante.primer_apellido,
          segundo_apellido: this.estudiante.segundo_apellido,
          genero: this.estudiante.genero,
          fecha_nacimiento: this.estudiante.fecha_nacimiento,

          // Apoderado
          id_persona_rol_apoderado: this.estudiante.id_persona_rol_apoderado,

          // dirección
          celular: this.estudiante.celular,
          correo: this.estudiante.correo,
          nombre_calle: this.estudiante.nombre_calle,
          numero: this.estudiante.numero,
          id_comuna: this.estudiante.id_comuna,
          id_pais: 45,

          // Antecedentes escolares
          id: this.estudiante.id_matricula,
          fecha_ingreso: this.estudiante.fecha_ingreso,
          repitencia: this.estudiante.repitencia,
          id_establecimiento: this.estudiante.id_establecimiento,
          id_cursos: this.estudiante.id_cursos,
          prioritario: this.estudiante.prioritario,
          beneficio: this.estudiante.beneficio,
          pie: this.estudiante.pie,
          religion: this.estudiante.religion,
          junaeb: this.estudiante.junaeb,
          autorizacion: this.estudiante.autorizacion,
          aprendizaje: this.estudiante.aprendizaje,
          transporte: this.estudiante.transporte,
          procedencia: this.estudiante.procedencia,
        }

        this.addMatricula(data).then(() => {
          const statusMatriculas = store.state.matriculas.status
          if (statusMatriculas === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Estudiante guardado 👍',
                icon: 'CheckIcon',
                text: 'El estudiante fue editado con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 3000,
            })
            if (tipo === 2) {
              this.$router.replace({
                name: 'matriculas',
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: store.state.matriculas.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }
    },

    changeTipoIdentificacion() {
      if (this.estudiante.tipo_identificacion === null) {
        this.estudiante.tipo_identificacion = 1
      }
    },

    validarFormulario() {
      this.v$.estudiante.$touch()
      return !this.v$.estudiante.$invalid;
    },
  },
  validations() {
    let estudiante = new Object()

    let rut_estudiante = new Object()
    let ipe_estudiante = new Object()

    if (this.estudiante.tipo_identificacion === 1) {
      rut_estudiante = {
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
      }
    } else if (this.estudiante.tipo_identificacion === 2) {
      ipe_estudiante = {
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 8 caracteres.', maxLength(8)),
      }
    }

    return {
      estudiante: {
        rut: rut_estudiante,
        ipe: ipe_estudiante,
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        tipo_identificacion: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_ingreso: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_comuna: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },

        id_cursos: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>