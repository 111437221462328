import { render, staticRenderFns } from "./TabEscolares.vue?vue&type=template&id=78dd6b47&"
import script from "./TabEscolares.vue?vue&type=script&lang=js&"
export * from "./TabEscolares.vue?vue&type=script&lang=js&"
import style0 from "./TabEscolares.vue?vue&type=style&index=0&id=78dd6b47&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports