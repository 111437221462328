<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="ml-1 mt-50 text-secondary">
        Antecedentes clínicos de el/la estudiante
      </div>
      <div class="mr-1 mt-50 text-muted small">Campos obligatorios <span class="text-danger">*</span></div>
    </div>

    <!-- FORM -->
      <!-- :show="cargando" -->
    <b-overlay
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25 mb-3">
        <b-col cols="6">
          <b-row>
            <!-- PREVISIÓN -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="prevision"
              >
                <template #label>
                  Previsión <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="salud.prevision"
                  placeholder="Selecciona el tipo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.prevision"
                  :options="optionsPrevision"
                  :class="v$.salud.prevision.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- @input="changePrevision()" -->
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.salud.prevision.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.salud.prevision.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- PATOLOGÍAS -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label="Patologías"
                label-for="patologias"
              >
                <b-form-input
                  id="patologias"
                  v-model="salud.patologias"
                  placeholder="Ingresa las patologias"
                  :state="v$.salud.patologias.$error === true
                  ? false
                  : null"
                  @blur.native="v$.salud.patologias.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.salud.patologias.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.salud.patologias.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- TRATAMIENTO MEDICO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-0 pt-0 text-right"
                label="Tratamiento médico"
                label-for="tratamiento_medico"
              >
                <b-form-input
                  id="tratamiento_medico"
                  v-model="salud.tratamiento_medico"
                  placeholder="Ingresa el tratamiento medico"
                  :state="v$.salud.tratamiento_medico.$error === true
                  ? false
                  : null"
                  @blur.native="v$.salud.tratamiento_medico.$touch"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.salud.tratamiento_medico.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.salud.patologias.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>

            <!-- HORARIO MEDICACIÓN -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-0 pt-0 text-right"
                label="Horario medicación"
                label-for="horario-medicacion"
              >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                  >
                    <feather-icon icon="ClockIcon" />
                  </b-input-group-prepend>
                  <cleave
                    id="horario_medicacion"
                    v-model='salud.horario_medicacion'
                    class="form-control"
                    :raw="false"
                    :options="time"
                    placeholder="HH:MM"
                    :onValueChanged="formatHoraInicio(salud.horario_medicacion)"
                  />

                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
      <b-row class="ml-25 mr-25 mt-2">
        <!-- SUB TITULO: Actividad Física -->
        <b-col
          cols="2"
          class="mb-2 mt-2"
        >
          <b-card-sub-title>
            Actividad física
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="10"
          class="mb-1 mt-2"
        >
          <b-card-sub-title >
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>

        <!-- ACTIVIDAD FÍSICA -->
        <b-col cols="6">
          <b-form-group
            label-class="font-weight-bold text-center"
            class="text-center"
            label="¿Tiene autorización para desarrollar actividades físicas?"
            label-for="actividad_fisica"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="salud.actividad_fisica"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- DOCUMENTO ACTIVIDAD FÍSICA -->
        <b-col cols="6">
          <b-form-group
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Presenta documento de actividad física"
            label-for="documento_actividad_fisica"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="salud.documento_actividad_fisica"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>


      <!-- Action Buttons -->
      <!-- <col-linea
        style="margin-left: -20px !important; margin-right: -20px !important;"
      />
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="4"
        >
          <b-button-group>
            <btnSubmit
              class="rounded mr-1"
              variant="primary"
              btnText="Guardar y salir"
              modulo="matriculas"
              @processBtn="submitOption(2)"
            />
            <btnSubmit
              class="rounded"
              variant="primary"
              btnText="Guardar"
              modulo="matriculas"
              @processBtn="submitOption(1)"
            />
          </b-button-group>
        </b-col>
      </b-row> -->
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable,
  BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupPrepend,
  BCardSubTitle, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store/index'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable,
    BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupPrepend,
    BCardSubTitle, BButtonGroup, BOverlay,
    vSelect,
    Cleave,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      salud: {
        prevision: null,
        actividad_fisica: false,
        documento_actividad_fisica: false,
        patologias: '',
        tratamiendo_medico: '',
        horario_medicacion: '',
        id_persona_rol_emergencia: null,
      },
      // otros
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
      // options
      optionsPrevision: [
        {
          prevision: 'Fonasa',
          title: 'Fonasa',
        },
        {
          prevision: 'Isapre',
          title: 'Isapre',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getMatriculaClinico: 'matriculas/getMatriculaClinico',
    }),
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.cargarSalud()
  },
  methods: {
    ...mapActions({
      fetchMatriculaClinico: 'matriculas/fetchMatriculaClinico',
      createMatriculaClinico: 'matriculas/addMatriculaClinico',
    }),
    cargarSalud() {
      this.fetchMatriculaClinico(this.matricula.id_matricula).then(() => {
        if (this.getMatriculaClinico.status !== 'error') {
          this.salud = {
            prevision: this.getMatriculaClinico.prevision,
            actividad_fisica: this.getMatriculaClinico.actividad_fisica === 1,
            documento_actividad_fisica: this.getMatriculaClinico.documento_actividad_fisica === '1',
            patologias: this.getMatriculaClinico.patologias,
            tratamiento_medico: this.getMatriculaClinico.tratamiento_medico,
            horario_medicacion: this.getMatriculaClinico.horario_medicacion,
            id_persona_rol_emergencia: this.getMatriculaClinico.id_persona_rol_emergencia,
          }
        }
        this.cargando = false
      })
    },
    submitOption(tipo) {
      this.v$.salud.$touch()
      if (!this.v$.salud.$invalid) {
        const data = {
          prevision: this.salud.prevision,
          actividad_fisica: this.salud.actividad_fisica,
          documento_actividad_fisica: this.salud.documento_actividad_fisica,
          patologias: this.salud.patologias,
          tratamiento_medico: this.salud.tratamiento_medico,
          horario_medicacion: this.salud.horario_medicacion,
          id_persona_rol_emergencia: this.salud.id_persona_rol_emergencia,
          id_matricula: this.matricula.id_matricula,
        }
        this.createMatriculaClinico(data).then(() => {
          const statusMatriculas = store.state.matriculas.status
          if (statusMatriculas === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Antecedentes clínicos guardados 👍',
                icon: 'CheckIcon',
                text: 'Los antecedentes clínicos han sido editados con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 3000,
            })
            if (tipo === 2) {
              this.$router.replace({
                name: 'matriculas',
              })
            }
          }
          else {
            this.$swal({
              title: 'Error!',
              text: store.state.matriculas.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }
    },
    // changePrevision() {
    // },
    formatHoraInicio(date) {
      // EJEMPLO: 13:30
      let horario_medicacion
      if (typeof date !== 'undefined') {
        const hora = date.split(':')[0]
        const minutos = date.split(':')[1]
        horario_medicacion = `${hora}:${minutos}`
        if (minutos === 'undefined') {
          horario_medicacion = hora
        }
        if (horario_medicacion === ':undefined') {
          horario_medicacion = '08:00'
        }
      }
      return horario_medicacion
    },
  },
  validations() {
    return {
      salud: {
        prevision: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        patologias: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 200 caracteres.', maxLength(200)),
        },
        tratamiento_medico: {
          // $autoDirty: true,
          maxLength: helpers.withMessage('Debe tener máximo 250 caracteres.', maxLength(250)),
        },
      },
    }
  },
  setup(props) {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
