<template>
  <div>
    <div class="d-flex justify-content-between mb-3">
      <div class="ml-1 mt-50 text-secondary">
        Antecedentes del núcleo familiar de el/la estudiante
      </div>
      <div class="mr-1 mt-50 text-muted small">Campos obligatorios <span class="text-danger">*</span></div>
    </div>

    <!-- FORM -->
      <!-- :show="cargando" -->
    <b-overlay
      spinner-variant="primary"
      variant="#000000"
    >
      <b-row class="ml-25 mr-25">
        <b-col cols="6">
          <b-row>
            <!-- INTEGRANTES -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="integrantes"
              >
                <template #label>
                  Integrantes <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="integrantes"
                  v-model="familiar.integrantes"
                  placeholder="Ingresa el número de integrantes"
                  :state="v$.familiar.integrantes.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.integrantes.$touch"
                  @keyup="familiar.integrantes = formatSoloNumeros(familiar.integrantes)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.familiar.integrantes.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.integrantes.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- CAMAS -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="camas"
              >
                <template #label>
                  Cantidad de camas  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="camas"
                  v-model="familiar.camas"
                  placeholder="Ingresa el número de camas"
                  :state="v$.familiar.camas.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.camas.$touch"
                  @keyup="familiar.camas = formatSoloNumeros(familiar.camas)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.familiar.camas.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.camas.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- HACINAMIENTO -->
            <b-col cols="12">
              <b-form-group
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="hacinamiento"
              >
                <template #label>
                  Hacinamiento  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="hacinamiento"
                  v-model="familiar.hacinamiento"
                  placeholder="Ingresa el hacinamiento"
                  :state="v$.familiar.hacinamiento.$error === true
                  ? false
                  : null"
                  @blur.native="v$.familiar.hacinamiento.$touch"
                  @keyup="familiar.hacinamiento = formatSoloNumeros(familiar.hacinamiento)"
                />
                <!-- Validaciones -->
                <b-form-invalid-feedback
                  v-if="v$.familiar.hacinamiento.$error"
                  id="integrantesInfo"
                  class="pb-0"
                >
                  <p
                    v-for="error of v$.familiar.hacinamiento.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">

          <b-row>

            <!-- NIVEL EDUCACIONAL PADRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="8"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nivel_educacional_padre"
              >
                <template #label>
                  Educación del padre <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="familiar.nivel_educacional_padre"
                  placeholder="Selecciona la educación"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.nivel_educacional"
                  :options="optionsNivelEducacion"
                  :class="v$.familiar.nivel_educacional_padre.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.familiar.nivel_educacional_padre.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.familiar.nivel_educacional_padre.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>

            <!-- NIVEL EDUCACIONAL PADRE -->
            <b-col cols="12">
              <b-form-group
                content-cols="8"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nivel_educacional_madre"
              >
                <template #label>
                  Educación del la madre <span class="text-danger">*</span>
                </template>
                <v-select
                  v-model="familiar.nivel_educacional_madre"
                  placeholder="Selecciona la educación"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.nivel_educacional"
                  :options="optionsNivelEducacion"
                  :class="v$.familiar.nivel_educacional_madre.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsPaises.length === 0" -->
                <div
                  v-if="v$.familiar.nivel_educacional_madre.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.familiar.nivel_educacional_madre.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>

      </b-row>

      <b-row class="ml-25 mr-25 mt-2">
        <!-- SUB TITULO: Entrega de certificados -->
        <b-col
          cols="3"
          class="mb-2 mt-2"
        >
          <b-card-sub-title>
            Entrega de documentos
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="9"
          class="mb-1 mt-2"
        >
          <b-card-sub-title >
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>

        <!-- CERTIFICADO DE NACIMIENTO -->
        <b-col cols="4">
          <b-form-group
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de nacimiento"
            label-for="certificado_nacimiento"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="familiar.certificado_nacimiento"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- CERTIFICADO DE ESTUDIO -->
        <b-col cols="4">
          <b-form-group
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de estudio"
            label-for="certificado_estudio"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="familiar.certificado_estudio"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- CERTIFICADO DE PERSONALIDAD -->
        <b-col cols="4">
          <b-form-group
            label-class="font-weight-bold text-center"
            class="text-center"
            label="Certificado de personalidad"
            label-for="certificado_personalidad"
          >
            <!-- label-cols-md="6" -->
            <b-form-checkbox
              checked="true"
              v-model="familiar.certificado_personalidad"
              class="custom-control-success"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

      </b-row>

      <!-- Action Buttons -->
      <!-- <col-linea
        style="margin-left: -20px !important; margin-right: -20px !important;"
      />
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
        </b-col>
        <b-col
          class="text-right"
          cols="12"
          md="4"
        >
          <b-button-group>
            <btnSubmit
              class="rounded mr-1"
              variant="primary"
              btnText="Guardar y salir"
              modulo="matriculas"
              @processBtn="submitOption(2)"
            />
            <btnSubmit
              class="rounded"
              variant="primary"
              btnText="Guardar"
              modulo="matriculas"
              @processBtn="submitOption(1)"
            />
          </b-button-group>
        </b-col>
      </b-row> -->
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  BCardSubTitle, BFormCheckbox, BButtonGroup, BOverlay,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'

import vSelect from 'vue-select'

// MIXINS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// Componentes reciclados
import colLinea from '../../../components/Form/colLinea.vue';
import btnSubmit from '../../../components/Form/btnSubmit.vue';

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
    BCardSubTitle, BFormCheckbox, BButtonGroup, BOverlay,
    vSelect,

    // Componentes reciclados
    colLinea,
    btnSubmit,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      cargando: true,
      familiar: {
        camas: null,
        certificado_estudio: false,
        certificado_nacimiento: false,
        certificado_personalidad: false,
        hacinamiento: null,
        id_persona_rol_madre: null,
        id_persona_rol_padre: null,
        integrantes: null,
        nivel_educacional_madre: null,
        nivel_educacional_padre: null,
        renta: null,
        vive: null,
      },
      // options
      optionsNivelEducacion: [
        {
          nivel_educacional: 1,
          title: 'Básica',
        },
        {
          nivel_educacional: 2,
          title: 'Básica - Incompleta',
        },
        {
          nivel_educacional: 3,
          title: 'Media',
        },
        {
          nivel_educacional: 4,
          title: 'Media - Incompleta',
        },
        {
          nivel_educacional: 5,
          title: 'Técnico Nivel Superior',
        },
        {
          nivel_educacional: 6,
          title: 'Técnico Nivel Superior - Incompleta',
        },
        {
          nivel_educacional: 7,
          title: 'Universitaria',
        },
        {
          nivel_educacional: 8,
          title: 'Universitaria - Incompleta',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getMatriculaNucleo: 'matriculas/getMatriculaNucleo',
    }),
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.cargaFamiliar()
  },
  methods: {
    ...mapActions({
      fetchMatriculaNucleo: 'matriculas/fetchMatriculaNucleo',
      createMatriculaNucleo: 'matriculas/addMatriculaNucleo',
    }),
    cargaFamiliar() {
      this.fetchMatriculaNucleo(this.matricula.id_matricula).then(() => {
        if (this.getMatriculaNucleo.status !== 'error') {
          this.familiar = {
            vive: this.getMatriculaNucleo.vive,
            renta: this.getMatriculaNucleo.renta,
            integrantes: this.getMatriculaNucleo.integrantes,
            camas: this.getMatriculaNucleo.camas,
            hacinamiento: this.getMatriculaNucleo.hacinamiento,
            certificado_nacimiento: this.getMatriculaNucleo.certificado_nacimiento === 1,
            certificado_estudio: this.getMatriculaNucleo.certificado_estudio === 1,
            certificado_personalidad: this.getMatriculaNucleo.certificado_personalidad === 1,
            nivel_educacional_padre: this.getMatriculaNucleo.nivel_educacional_padre,
            nivel_educacional_madre: this.getMatriculaNucleo.nivel_educacional_madre,
            id_persona_rol_padre: this.getMatriculaNucleo.id_persona_rol_padre,
            id_persona_rol_madre: this.getMatriculaNucleo.id_persona_rol_madre,
          }
        }
        this.cargando = false
      })
    },
    submitOption(tipo) {
      this.v$.familiar.$touch()
      if (!this.v$.familiar.$invalid) {
        const data = {
          vive: this.familiar.vive,
          renta: this.familiar.renta,
          integrantes: this.familiar.integrantes,
          camas: this.familiar.camas,
          hacinamiento: this.familiar.hacinamiento,
          certificado_nacimiento: this.familiar.certificado_nacimiento,
          certificado_estudio: this.familiar.certificado_estudio,
          certificado_personalidad: this.familiar.certificado_personalidad,
          nivel_educacional_padre: this.familiar.nivel_educacional_padre,
          nivel_educacional_madre: this.familiar.nivel_educacional_madre,
          id_persona_rol_padre: this.familiar.id_persona_rol_padre,
          id_persona_rol_madre: this.familiar.id_persona_rol_madre,
          id_matricula: this.matricula.id_matricula,
        }
        this.createMatriculaNucleo(data).then(() => {
          const statusPersonas = store.state.matriculas.status
          if (statusPersonas === 'success') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Núcleo familiar guardado 👍',
                icon: 'CheckIcon',
                text: 'El núcleo familiar fue editado con éxito!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 3000,
            })
            if (tipo === 2) {
              this.$router.replace({
                name: 'matriculas',
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: store.state.matriculas.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
      }
    },
  },
  validations() {
    return {
      familiar: {
        integrantes: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        camas: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        hacinamiento: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_padre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        nivel_educacional_madre: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          // maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
