<template>
  <b-card class="pt-0 p-0">
    <b-tabs
      v-if="selectedMatricula"
      pills
      class="primary m-0 pb-2"
      style="margin: -10px -10px -26px -10px! important; text-light"
    >
      <!-- ESTUDIANTE -->
      <b-tab lazy active>
        <template #title>
          <feather-icon
            icon="UserCheckIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Estudiante</span>
        </template>
        <TabEstudiante
          class="border-top pt-50"
          crud="u"
          :matricula="selectedMatricula"
        />
      </b-tab>

      <!-- APODERADO -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Apoderado</span>
        </template>
        <TabApoderado
          class="border-top pt-50"
          crud="u"
          :matricula="selectedMatricula"
        />
      </b-tab>

      <!-- FAMILIAR -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Núcleo Familiar</span>
        </template>
        <TabFamiliar
          class="border-top pt-50"
          crud="u"
          :matricula="selectedMatricula"
        />
      </b-tab>

      <!-- SALUD -->
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Antecedentes de Salud</span>
        </template>
        <TabSalud
          class="border-top pt-50"
          crud="u"
          :matricula="selectedMatricula"
        />
      </b-tab>

    </b-tabs>

    <!-- Action Buttons -->
    <b-row class="border-top pt-1 mt-1">
      <b-col
        cols="12"
        md="8"
      >
      </b-col>
      <b-col
        class="text-right"
        cols="12"
        md="4"
      >
        <b-button-group>
          <btnSubmit
            class="rounded"
            variant="primary"
            btnText="Guardar"
            modulo="matriculas"
          />
            <!-- @processBtn="submitOption(1)" -->
          <!-- <btnSubmit
            class="rounded"
            variant="primary"
            btnText="Guardar y salir"
            modulo="matriculas"
          /> -->
          <!-- @processBtn="submitOption(2)" -->
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// Etiquetas //
import {
  BCard, BTabs, BTab, BRow, BCol, BButtonGroup,
} from 'bootstrap-vue'

import { mapState } from 'vuex'

// Componentes hijos
import TabEstudiante from './MatriculasFormTabs/TabEstudiante.vue'
import TabApoderado from './MatriculasFormTabs/TabApoderado.vue'
import TabFamiliar from './MatriculasFormTabs/TabFamiliar.vue'
import TabSalud from './MatriculasFormTabs/TabSalud.vue'
// import TabEscolar from './MatriculasFormTabs/TabEscolar.vue'

// Componente reciclado
import btnSubmit from '../../components/Form/btnSubmit.vue';

export default {
  components: {
    BCard, BTabs, BTab, BRow, BCol, BButtonGroup,

    // Componentes hijos
    TabEstudiante,
    TabApoderado,
    TabFamiliar,
    TabSalud,
    // TabEscolar,

    // Componente reciclado
    btnSubmit,
  },
  computed: {
    ...mapState('matriculas', ['selectedMatricula']),
  },
}
</script>
